export default [
    {
      path: '/banner',
      name: 'banner-read',
      component: () => import('@/views/banner/banner-list/BannerList.vue'),
      meta: {
        resource: 'Blog',
        action: 'read',
      },
    },
    
  ]
  