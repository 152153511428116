export default [
  {
    path: '/meterial',
    name: 'meterial-read',
    component: () => import('@/views/meterial/meterial-list/MeterialList.vue'),
    meta: {
      resource: 'meterial',
      action: 'read',
    },
  },
  { 
    path: '/meterial/medicine/:id',
    name: 'meterial-medicine-edit',
    component: () => import('@/views/meterial/meterial-detail/MeterialDetail.vue'),
    meta: {
      resource: 'meterial',
      action: 'edit',
    },
},
  {
    path: '/meterial/category',
    name: 'meterial_category-read',
    component: () => import('@/views/meterial/meterial-category/category-list/CategoryList.vue'),
    meta: {
      resource: 'meterial',
      action: 'read',
    },
  },
]
