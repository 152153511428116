export default [
    {
      path: '/feedback',
      name: 'feedback-read',
      component: () => import('@/views/feedback/feedback-list/FeedbackList.vue'),
      meta: {
        resource: 'Feedback',
        action: 'read',
      },
    },
    
  ]
  