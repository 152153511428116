export default [
    {
      path: '/appointment',
      name: 'appointment-read',
      component: () => import('@/views/appointment/appointment-list/AppointmentList.vue'),
      meta: {
        resource: 'appointment',
        action: 'read',
      },
    },
    {
      path: '/appointment-calendar',
      name: 'appointment-calendar',
      component: () => import('@/views/appointment/appointment-list/AppointmentCalendar.vue'),
      meta: {
        resource: 'appointment',
        action: 'appointment_calendar',
      },
    },
  ]
  