export default [
    {
      path: '/report',
      name: 'report-read',
      component: () => import('@/views/report/report-list/ReportList.vue'),
      meta: {
        resource: 'report',
        action: 'read',
      },
    },
   
    {
        path: '/report/stocks',
        name: 'report-stocks',
        component: () => import('@/views/report/stock-list/StockList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/fee/medical',
        name: 'report-fee-medical',
        component: () => import('@/views/report/medical-fee-list/MedicalFeeList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/fee/assistant',
        name: 'report-fee-assistant',
        component: () => import('@/views/report/assistant-fee-list/AssistantFeeList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
     
      {
        path: '/report/sales',
        name: 'report-sales',
        component: () => import('@/views/report/sales-list/SalesList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/sales/daily',
        name: 'report-sales-daily',
        component: () => import('@/views/report/sales-daily/SalesDaily.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/sales/types',
        name: 'report-sales-types',
        component: () => import('@/views/report/sales-types/SalesTypes.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/sales/types-detail',
        name: 'report-sales-types-detail',
        component: () => import('@/views/report/sales-types-detail/SalesTypesDetail.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/user-product',
        name: 'report-user-product',
        component: () => import('@/views/report/user-product/UserProduct.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/date-products',
        name: 'report-date-products',
        component: () => import('@/views/report/date-products/DateProducts.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/name-products',
        name: 'report-name-products',
        component: () => import('@/views/report/name-products/NameProducts.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/patients-new',
        name: 'report-patients-new',
        component: () => import('@/views/report/patients-new/PatientsNew.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/patients-list',
        name: 'report-patients-list',
        component: () => import('@/views/report/patients-list/PatientsList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/patients-receipt',
        name: 'report-patients-receipt',
        component: () => import('@/views/report/patients-receipt/PatientsReceipt.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/patients-receipt-sales',
        name: 'report-patients-receipt-sales',
        component: () => import('@/views/report/patients-receipt-sales/PatientsReceiptSales.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
      {
        path: '/report/services',
        name: 'report-services',
        component: () => import('@/views/report/services-list/ServicesList.vue'),
        meta: {
          resource: 'report',
          action: 'read',
        },
      },
     
     
      
   
 
    
  ]
  