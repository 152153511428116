export default [
    {
      path: '/receipt',
      name: 'receipt-read',
      component: () => import('@/views/receipt/receipt-list/ReceiptList.vue'),
      meta: {
        resource: 'receipt',
        action: 'read',
      },
    },
    {
        path: '/receipt/create/:user_id',
        name: 'receipt-create',
        component: () => import('@/views/receipt/receipt-form/ReceiptForm.vue'),
        meta: {
          resource: 'Queue',
          action: 'read',
        },
      },
      {
        path: '/receipt/edit/:id',
        name: 'receipt-edit',
        component: () => import('@/views/receipt/receipt-form/ReceiptForm.vue'),
        meta: {
          resource: 'Queue',
          action: 'read',
        },
      },
      {
        path: '/receipt/opd_create/:opdcard_id',
        name: 'receipt-opd-create',
        component: () => import('@/views/receipt/receipt-form/ReceiptForm.vue'),
        meta: {
          resource: 'Queue',
          action: 'read',
        },
      },
      {
        path: '/receipt/payment-confirm/:id',
        name: 'receipt-confirm',
        component: () => import('@/views/receipt/receipt-confirm/ReceiptConfirm.vue'),
        meta: {
          resource: 'Queue',
          action: 'read',
        },
      },

      {
        path: '/receipt/status',
        name: 'receipt-status-read',
        component: () => import('@/views/receipt/status-list/StatusList.vue'),
        meta: {
          resource: 'receipt',
          action: 'status_read',
        },
      },
   
 
    
  ]
  