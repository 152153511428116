export default [
    { 
        path: '/medicine/stock/:id',
        name: 'stock-medicine-edit',
        component: () => import('@/views/medicine/medicine-detail/MedicineDetail.vue'),
        meta: {
          resource: 'stock',
          action: 'edit',
        },
    },
    { 
        path: '/stock/cycle',
        name: 'stock-cycle-read',
        component: () => import('@/views/stock/stock-cycle-list/StockList.vue'),
        meta: {
          resource: 'stock',
          action: 'read',
        },
    },
    ]