export default [
  {
    path: '/course/set',
    name: 'courseset-read',
    component: () => import('@/views/course/course-set-list/CourseSetList.vue'),
    meta: {
      resource: 'courseset',
      action: 'read',
    },
  },
]
