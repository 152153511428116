export default [
    {
      path: '/navigation',
      name: 'navigation-read',
      component: () => import('@/views/navigation/navigation-list/NavigationList.vue'),
      meta: {
        resource: 'Blog',
        action: 'read',
      },
    },
    
  ]
  