export default [
    {
      path: '/equipment',
      name: 'equipment-read',
      component: () => import('@/views/equipment/equipment-list/EquipmentList.vue'),
      meta: {
        resource: 'Equipment',
        action: 'read',
      },
    },
 
    
  ]
  