import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://app.clinixapp.co/api/',
  //baseURL: 'http://localhost:3002/api/',
  headers:{
   
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json'
  
  
  },
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
