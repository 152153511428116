export default [
    {
      path: '/blog',
      name: 'blog-read',
      component: () => import('@/views/blog/blog-list/BlogList.vue'),
      meta: {
        resource: 'Blog',
        action: 'read',
      },
    },
    {
        path: '/blog/create',
        name: 'blog-create',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },
    },
    {
      path: '/blog-category',
      name: 'blog-category-read',
      component: () => import('@/views/blog/category-list/CategoryList.vue'),
      meta: {
        resource: 'Blog',
        action: 'read',
      },
    },
    {
        path: '/blog-category/create',
        name: 'blog-category-create',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },
    },
    
    
  ]
  