export default [
    {
      path: '/checklists',
      name: 'checklists-read',
      component: () => import('@/views/checklists/checklists-list/ChecklistsList.vue'),
      meta: {
        resource: 'Checklists',
        action: 'read',
      },
    },
    {
      path: '/checklists/set',
      name: 'checklists-set-read',
      component: () => import('@/views/checklists/checklists-set-list/ChecklistsSetList.vue'),
      meta: {
        resource: 'Checklists',
        action: 'read',
      },
    },
    {
      path: '/checklists/category',
      name: 'checklists-category-read',
      component: () => import('@/views/checklists/category-list/CategoryList.vue'),
      meta: {
        resource: 'Checklists',
        action: 'category_read',
      },
    },
    {
      path: '/checklists/advice',
      name: 'checklists-advice-read',
      component: () => import('@/views/checklists/advice-list/AdviceList.vue'),
      meta: {
        resource: 'Checklists',
        action: 'advice_read',
      },
    },
    { 
      path: '/checklists/set/:id',
      name: 'checklist-detail',
      component: () => import('@/views/checklists/checklists-detail/ChecklistsDetail.vue'),
      meta: {
        resource: 'Checklists',
        action: 'edit',
      },
    },
  ]
  