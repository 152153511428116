export default [
    {
      path: '/medicine',
      name: 'medicine-read',
      component: () => import('@/views/medicine/medicine-list/MedicineList.vue'),
      meta: {
        resource: 'medicine',
        action: 'read',
      },
    },
    {
      path: '/medicine/category',
      name: 'medicine_category-read',
      component: () => import('@/views/medicine/medicine-category/category-list/CategoryList.vue'),
      meta: {
        resource: 'medicine',
        action: 'category_read',
      },
    },
    {
      path: '/medicine/group',
      name: 'medicine_group-read',
      component: () => import('@/views/medicine/medicine-group/group-list/GroupList.vue'),
      meta: {
        resource: 'medicine',
        action: 'group_read',
      },
    },
  ]
  