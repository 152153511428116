export default [
    {
      path: '/groups',
      name: 'group-read',
      component: () => import('@/views/group/group-list/GroupList.vue'),
      meta: {
        resource: 'Group',
        action: 'read',
      },
    },
    
  ]
  