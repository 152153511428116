export default [
    {
        path: '/branch',
        name: 'branch-read',
        component: () => import('@/views/branch/branch-list/BranchList.vue'),
        meta: {
            //layout: 'full',
            resource: 'Brach',
            action:'read'
        },
    }, 
    
  

    
]