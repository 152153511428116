export default [
    {
      path: '/voucher',
      name: 'voucher-read',
      component: () => import('@/views/voucher/voucher-list/VoucherList.vue'),
      meta: {
        resource: 'voucher',
        action: 'read',
      },
    },
    
    {
      path: '/voucher/types',
      name: 'voucher-type-read',
      component: () => import('@/views/voucher/voucher-category/category-list/CategoryList.vue'),
      meta: {
        resource: 'Checklists',
        action: 'category_read',
      },
    },
    
  ]
  