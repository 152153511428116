export default [
    {
      path: '/notify',
      name: 'linenotify-read',
      component: () => import('@/views/linenotify/line-list/LineList.vue'),
      meta: {
        resource: 'Linenotify',
        action: 'read',
      },
    },

  ]
  