export default [
    {
      path: '/warehouse',
      name: 'warehouse-read',
      component: () => import('@/views/warehouse/warehouse-list/WarehouseList.vue'),
      meta: {
        resource: 'warehouse',
        action: 'read',
      },
    },
]