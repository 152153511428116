export default [
    {
      path: '/widgets',
      name: 'widgets-read',
      component: () => import('@/views/widgets/widgets-list/WidgetsList.vue'),
      meta: {
        resource: 'Widgets',
        action: 'read',
      },
    },
    
  ]
  