export default [
    {
      path: '/pages',
      name: 'pages-read',
      component: () => import('@/views/pages/pages-list/PagesList.vue'),
      meta: {
        resource: 'Pages',
        action: 'read',
      },
    },
    

    
    
]
  