export default [
    {
      path: '/portfolio',
      name: 'portfolio-read',
      component: () => import('@/views/portfolio/portfolio-list/PortfolioList.vue'),
      meta: {
        resource: 'Portfolio',
        action: 'read',
      },
    },
    {
        path: '/portfolio/create',
        name: 'portfolio-create',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Portfolio',
          action: 'create',
        },
    },
    {
      path: '/portfolio-category',
      name: 'portfolio-category-read',
      component: () => import('@/views/portfolio/category-list/CategoryList.vue'),
      meta: {
        resource: 'Portfolio',
        action: 'read',
      },
    },
    {
        path: '/portfolio-category/create',
        name: 'portfolio-category-create',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Portfolio',
          action: 'create',
        },
    },
    
    
  ]
  