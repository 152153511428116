export default [
    {
      path: '/opd',
      name: 'opd-read',
      component: () => import('@/views/opd/opd-list/OpdList.vue'),
      meta: {
        resource: 'Queue',
        action: 'read',
      },
    },
    {
      path: '/opd/add/:id',
      name: 'opd-create',
      component: () => import('@/views/opd/opd-form/OpdForm.vue'),
      meta: {
        resource: 'Queue',
        action: 'read',
      },
    },
    {
      path: '/opd/:id',
      name: 'opd-detail',
      component: () => import('@/views/opd/opd-form/OpdDetail.vue'),
      meta: {
        resource: 'Queue',
        action: 'read',
      },
    },
 
    
  ]
  