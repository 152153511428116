export default [
    {
      path: '/services',
      name: 'services-read',
      component: () => import('@/views/services/services-list/ServicesList.vue'),
      meta: {
        resource: 'Services',
        action: 'read',
      },
    },
    
  ]
  