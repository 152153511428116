import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import dashboard from './routes/dashboard'


import users from './routes/users'
import blog from './routes/blog'
import portfolio from './routes/portfolio'
import banner from './routes/banner'
import navigation  from './routes/navigation'
import groups from './routes/groups'

import services from './routes/services'

import settings from './routes/settings'
import widgets from './routes/widgets'
import feedback from './routes/feedback'
import pages from './routes/pages'
import patients from './routes/patients'
import opd from './routes/opd'
import course from './routes/course'
import equipment from './routes/equipment'
import branch from './routes/branch'
import queue  from './routes/queue'
import checklists from './routes/checklists'
import voucher from './routes/voucher'
import medicine from './routes/medicine'
import meterial from './routes/meterial'
import unit from './routes/unit'
import warehouse from './routes/warehouse'
import stock from './routes/stock'
import appointment from './routes/appointment'
import room from './routes/room'
import receipt from './routes/receipt'
import linenotify from './routes/linenotify'
import courseset from './routes/courseset'
import report from './routes/report'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
   // { path: '/', redirect: { name: 'dashboard-analytics' } },
   { path: '/', redirect: { name: 'dashboard-coparate' } },
    ...dashboard,

    ...users,
    ...blog,
    ...portfolio,
    ...services,
    ...banner,
    ...navigation,
    ...groups,
    ...widgets,
    ...settings,
    ...feedback,
    ...pages,
    ...patients,
    ...opd,
    ...course,
    ...medicine,
    ...meterial,
    ...unit,
    ...warehouse,
    ...stock,
    ...equipment,
    ...branch,
    ...queue,
    ...checklists,
    ...voucher,
    ...appointment,
    ...room,
    ...receipt,
    ...linenotify,
    ...courseset,
    ...report,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
