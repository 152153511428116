export default [
  {
    path: '/queue',
    name: 'queue-read',
    component: () => import('@/views/queue/queue-list/QueueList.vue'),
    meta: {
      resource: 'Queue',
      action: 'queue_examination',
    },
  },
  {
    path: '/queue/service',
    name: 'queue-service',
    component: () => import('@/views/queue/queue-service-list/QueueServiceList.vue'),
    meta: {
      resource: 'Queue',
      action: 'queue_services',
    },
  },
  {
    path: '/queue/service/create/:id',
    name: 'queue-service-add',
    component: () => import('@/views/queue/queue-service-form/QueueServiceForm.vue'),
    meta: {
      resource: 'Queue',
      action: 'queue_services',
    },
  },
  {
    path: '/queue/payment',
    name: 'queue-pament',
    component: () => import('@/views/queue/queue-list/QueueList.vue'),
    meta: {
      resource: 'Queue',
      action: 'queue_payment',
    },
  },
   
   
    
   
 
    
  ]
  