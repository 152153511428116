export default [
    {
      path: '/room',
      name: 'room-read',
      component: () => import('@/views/room/room-list/RoomList.vue'),
      meta: {
        resource: 'room',
        action: 'read',
      },
    },
    {
      path: '/room/:queue_id',
      name: 'room-detail',
      component: () => import('@/views/room/room-detail/RoomDetail.vue'),
      meta: {
        resource: 'room',
        action: 'create',
      },
    },
  ]
  