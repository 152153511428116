export default [
  {
    path: '/course',
    name: 'course-read',
    component: () => import('@/views/course/course-list/CourseList.vue'),
    meta: {
      resource: 'course',
      action: 'read',
    },
  },
  { 
    path: '/course/medicine/:id',
    name: 'course-medicine-edit',
    component: () => import('@/views/course/course-detail/CourseDetail.vue'),
    meta: {
      resource: 'course',
      action: 'edit',
    },
},
  {
    path: '/course/category',
    name: 'course_category-read',
    component: () => import('@/views/course/course-category/category-list/CategoryList.vue'),
    meta: {
      resource: 'course',
      action: 'category_read',
    },
  },
]
