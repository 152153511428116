export default [
    {
      path: '/settings/groups',
      name: 'settings-groups',
      component: () => import('@/views/settings/permissions/Groups.vue'),
      meta: {
        resource: 'Settings',
        action: 'permission',
      },
    },
    {
      path: '/settings/general',
      name: 'settings-general',
      component: () => import('@/views/settings/general/GeneralForm.vue'),
      meta: {
        resource: 'Settings',
        action: 'general',
      },
    },

    
    
  ]
  